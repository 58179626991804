function imgUrl(filename) {
    return `/assets/${filename}`
}

const assets = {
    x: imgUrl('x.svg'),
    arrow_down: imgUrl('arrow_down.svg'),
    landing_logo: imgUrl('landing_logo.gif'),
    menu_logo: imgUrl('menu_logo.svg'),
    menu_bars: imgUrl('menu_bars.svg'),
    menu_arrow_down: imgUrl('menu_arrow_down.svg'),
    facebook: imgUrl('facebook.svg'),
    instagram: imgUrl('instagram.svg'),
    linkedin: imgUrl('linkedin.svg'),
};

export default assets;
