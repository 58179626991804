import React, {Component, useMemo} from 'react';
import { PageTpl, Container, PageHeader } from '../components';
import PageBuilder from '../page_builder/PageBuilder';
import GoogleMapReact from 'google-map-react';
import config from '../config';
import {postContact} from '../services/contact';
import {gaEvent} from '../services/analytics'

const map = {
    center: {
        lat:  -34.9314241,
        lng: 138.5961823,
    },
    zoom: 16,
    label: 'JP Media, Level 2 36 Field St, Adelaide SA 5000',
};

const fields = ['name', 'company', 'enquiry', 'email', 'message'];


class ContactComp extends Component {
    state = {
        busy: false,
        submittedOk: false
    };

    onSubmit = (e) => {
        const obj = {};
        let isValid = true;
        for (const f of fields) {
            const domInput = document.getElementById(f);
            const valid = domInput.checkValidity();
            domInput.classList.remove('invalid');
            const value = domInput.value;
            if (!valid) {
                isValid = false;
                domInput.classList.add('invalid');
            }
            obj[f] = value;
        }

        if (!isValid) {
            return;
        }

        this.setState({busy: true});
        postContact(obj)
            .then((response) => {
                if (response.status == 200) {
                    gaEvent({
                        category: 'contact',
                        action: 'contact_form_submitted',
                        label: obj.email
                    })
                    this.setState({busy: false, submittedOk: true});
                } else {
                    alert('An error occured submitting your enquiry');
                    this.setState({busy: false});
                }
            })
            .catch((error) => {
                alert('An error occured submitting your enquiry');
                this.setState({busy: false});
            });
    };

    renderMarkers = (gmap, gmaps) => {
        let marker = new gmaps.Marker({
            position: gmap.center,
            map: gmap,
            animation: gmaps.Animation.DROP,
            title: map.label,
        });
        return marker;
    };

    render() {
        const {busy, submittedOk} = this.state;
        let pageBuilder = this.props.page?.pageBuilder;
        return (
            <PageTpl className="contact-page">
                <PageHeader title="Contact" />
                <Container className="form">
                    <div className="col-1">
                        <PageBuilder page={pageBuilder} />
                    </div>
                    <div className="col-2">
                        {!submittedOk &&
                            <>
                                <input placeholder="Your Name" id="name" required />
                                <input placeholder="Company" id="company" />
                                <input placeholder="Enquiry Type" id="enquiry" required />
                                <input placeholder="Email" id="email" type="email" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" required />
                                <textarea placeholder="Your message..." id="message" rows="8" required />
                                <button className="btn-black submit" onClick={this.onSubmit} disabled={busy}>
                                    Send
                                </button>
                            </>
                        }
                        {submittedOk && <div className="message">Your enquiry has been submitted</div>}
                    </div>
                </Container>
                <Container wrapperClassName="map">
                    <GoogleMapReact
                        bootstrapURLKeys={{key: config.GOOGLE_MAPS_API_KEY}}
                        defaultCenter={map.center}
                        defaultZoom={map.zoom}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({map, maps}) => this.renderMarkers(map, maps)}
                    ></GoogleMapReact>
                </Container>
            </PageTpl>
        );
    }
}

function ContactComp2({ page, ...other }) {
    let pageBuilder = page?.pageBuilder;

    if (pageBuilder) {
        if (typeof window != 'undefined') {
            window.gaEvent = gaEvent
        }

        pageBuilder = pageBuilder.map(comp => {
            if (comp.acfFcLayout == 'single_content_module') {
                let newContent = comp.Content.replace('href="mailto:', 
                    `onClick="window.gaEvent({ category: 'contact', action: 'email_clicked' })" href="mailto:`)
                newContent = newContent.replace('href="tel:', 
                    `onClick="window.gaEvent({ category: 'contact', action: 'phone_clicked' })" href="mailto:`)
                
                return {
                    ...comp,
                    Content: newContent
                }
            }

            return comp
        })
    }

    return (
        <ContactComp page={{pageBuilder}} {...other}/>
    )
}

export default ContactComp2;
