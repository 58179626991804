import React, {Component} from 'react';
import {ImageBlurhash} from '../components/image'

export default class FullWidthImageModule extends Component {
    render() {
        const {image, name} = this.props;
        return (
            <div className={name}>
                <ImageBlurhash src={image} alt="" />
            </div>
        );
    }
}
