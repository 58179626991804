import React, {Component} from 'react';
import {Container} from '../components';
import Link from 'next/link'

export default class CalloutModule extends Component {
    render() {
        const {title, subTitle, buttonLabel, buttonLink, name} = this.props;
        return (
            <Link href={buttonLink}>
                <Container wrapperClassName={name}>
                    <div className="title">{title}</div>
                    <div className="subtitle">{subTitle}</div>
                    <div to={buttonLink} className="btn btn-opacity">
                        {buttonLabel}
                    </div>
                </Container>
            </Link>
        );
    }
}
