import {serializeURI} from '../utils/fetch'
import config from '../config'

export const postContact = (args) => {
    const params = serializeURI(args);
    return fetch(`${config.API.BASEPATH}/add-contact-entry?${params}`, {method: 'POST'});
}

export const postCareerContact = (args) => {
    const params = serializeURI(args);
    return fetch(`${config.API.BASEPATH}/add-career-contact-entry?${params}`, {method: 'POST'});       
}