//
// Container provides a wrapper which stretches the whole width of the window
// with a container inner which is centered and stretches the max width of the content
//
import React, {Component} from 'react';

export default class Container extends Component {
    render() {
        const {children, wrapperClassName, className, wrapperStyle, style, wrapperId, id, before: Before, after: After, wrapperOnClick, onClick} = this.props;

        return (
            <div className={`container-wrapper ${wrapperClassName || ''}`} style={wrapperStyle} id={wrapperId} onClick={wrapperOnClick}>
                {Before && <Before />}
                <div className={`container ${className || ''}`} style={style} id={id} onClick={onClick}>
                    {children}
                </div>
                {After && <After />}
            </div>
        );
    }
}
