import {Image, ImageBlurhash, PageTpl, HoverCard} from '../components';

export const DevTestPage = (props) => {

// const {title, description, className, buttonText, clickable, image, underlayImage} = this.props;

    return (
        <div>
            helo
{/*
            <div 
                style={{
                    width: 100,
                    height: 100
                }} 
                className="100x100"
            >
                <Image src="https://jpwebsiteapi.kinsta.cloud/wp-content/uploads/2021/07/Screen-Shot-2021-07-26-at-4.40.41-pm-e1627283684166.png"/>
            </div>
            */}

            {/*
            <div 
                style={{
                    width: 800,
                    height: 800
                }} 
                className="100x100"
            >
                <ImageBlurhash src="https://jpwebsiteapi.kinsta.cloud/wp-content/uploads/2021/03/King_William_Day_201029-scaled.jpg"
                    //width={200}
                    //height={200}
                />
            </div>
            */}

            <HoverCard
                title="title"
                description="desc"
                image="https://jpwebsiteapi.kinsta.cloud/wp-content/uploads/2021/12/Nabula-Brdar-1-e1640057312530.jpeg"
                //underlayImage="https://jpwebsiteapi.kinsta.cloud/wp-content/uploads/2021/07/Screen-Shot-2021-07-26-at-4.40.41-pm-e1627283684166.png"
            />
        </div>
    )
}