import React, {Component} from 'react';
import Container from './Container';
import HoverCard from './HoverCard';

export default class GridCard extends Component {
    render() {
        const {className, content, component, componentProps} = this.props;
        const CardComp = component || HoverCard;

        return (
            <Container className={`${className || ''} card-grid`}>
                {content?.map((item, index) => {
                    return <CardComp key={index} {...componentProps} {...item} />;
                })}
            </Container>
        );
    }
}
