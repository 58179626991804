import React, {Component} from 'react';
import { withRouter } from '../store/router'
import {CSSTransition} from 'react-transition-group';
import Container from './Container';
import {routes} from '../services/routes';
import Social from './Social';
import assets from '../assets';
import {Image} from './image'

class Menu extends Component {
    state = {
        visible: false,
        activeLi: null,
        menuBarsVisible: false,
    };

    constructor(props) {
        super(props);

        this.timer = setInterval(() => {
            this.setState({menuBarsVisible: !this.state.menuBarsVisible});
        }, 5000);
    }

    toggleLiActive = (li) => {
        if (!li) {
            return {};
        }

        // https://www.w3schools.com/howto/howto_js_collapsible.asp
        // https://www.w3schools.com/howto/tryit.asp?filename=tryhow_js_accordion_animate
        const buttonContainer = li.children.length >= 1 && li.children[0];
        const panel = li.children.length >= 2 && li.children[1];

        if (panel) {
            if (panel.style.maxHeight) {
                buttonContainer.classList.remove('expanded');
                panel.style.maxHeight = null;
            } else {
                buttonContainer.classList.add('expanded');
                panel.style.maxHeight = panel.scrollHeight + 'px';
            }
        }

        return {li, panel};
    };

    onNavClick = (e, n) => {
        const {router} = this.props;
        const {pathname} = n;

        if (pathname) {
            this.setState({visible: false});
            router.push(pathname);
        }
    }

    onToggleExpand = (e, n) => {
        const {children} = n;
        const {target} = e;
        let activeLi = this.state.activeLi;

        // collapse the old active menu item
        if (activeLi) {
            this.toggleLiActive(activeLi);

            if (target.parentElement.parentElement.parentElement === activeLi) {
                activeLi = null;
                this.setState({activeLi: null});
                return;
            }
            activeLi = null;
        }

        // we are dealing with a accordian menu button
        if (children) {
            // active the new menu item
            const {panel, li} = this.toggleLiActive(target.parentElement.parentElement.parentElement);
            if (panel) {
                this.setState({activeLi: li});
            }

            return;
        }

        this.setState({visible: false, activeLi});
    }

    renderNav = (n, index) => {
        const locationPath = this.props.router.asPath
        //const {location} = this.props;
        let {pathname, title, component, children} = n;
        if (!title) {
            return null;
        }

        if (!component) {
            pathname = ''; // stop the user going to bad paths!
        }

        const isLast = pathname === '/contact';
        const active = locationPath === pathname;
        //console.log("pathname:", pathname, "title:", title);

        return (
            <li key={`${pathname}-${index}`} id={`${pathname}-${index}`}>
                <div className="button-container">
                    <button onClick={(e) => this.onNavClick(e, n)} className={`btn-link ${children ? 'parent' : ''} ${active ? 'active' : ''} ${pathname ? 'valid' : 'invalid'}`}>
                        <span dangerouslySetInnerHTML={{__html: title}} className="label" />
                    </button>
                    <button onClick={(e) => this.onToggleExpand(e, n)} className={`btn-arrow ${children ? 'parent' : ''} ${active ? 'active' : ''}`}>
                        <Image src={assets['menu_arrow_down']} className="arrow" />
                    </button>
                </div>
                {children && <ul>{children.map(this.renderNav)}</ul>}
                {isLast && <Social />}
            </li>
        );
    };

    render() {
        const {visible, menuBarsVisible} = this.state;

        // we pass the current pathname into the navlink such that on the next page we can tell which page we came from
        // which we need to know in order to render the transition
        return (
            <div className="menu">
                <Container className="btn-menu-container">
                    <div className="btn-menu" onClick={() => this.setState({visible: true})}>
                        <CSSTransition in={menuBarsVisible} timeout={500} classNames="fade" key="menu_bars">
                            <Image src={assets.menu_bars} alt="" />
                        </CSSTransition>
                        <CSSTransition in={!menuBarsVisible} timeout={500} classNames="fade" key="menu_logo">
                            <Image src={assets.menu_logo} alt="" />
                        </CSSTransition>
                    </div>
                </Container>

                <CSSTransition in={visible} timeout={300} classNames="slide">
                    <div className="dropdown">
                        <Container>
                            <ul>{routes.map(this.renderNav)}</ul>
                            <div className="btn-x-container">
                                <button className="btn-x" onClick={() => this.setState({visible: false})}>
                                    <Image src={assets.x} alt="close button" />
                                </button>
                            </div>
                        </Container>
                    </div>
                </CSSTransition>
            </div>
        );
    }
}

export default withRouter(Menu);
