import React, {Component} from 'react';
import { useRouterStore, useRouter } from './store/router'
import {TransitionGroup, Transition} from 'react-transition-group';
import {gsap} from 'gsap';
import Config from './config';
import PageNotFound from './pages/PageNotFound';
import PageBuilderPage from './pages/PageBuilderPage';
import { usePagesStore } from './store/pages';
import { findRoute, cleanPathname } from './services/routes';
import {matchPath} from './utils/path-match';

const duration = Config.DEBUG.SLOW_MOTION_PAGE_TRANSITION ? 40.0 : 0.6;
//
// Used this as a guide to get me started:
// https://css-tricks.com/animating-between-views-in-react/
//
// It has some stuff about animating at the right time so might need to look into this (ie. on page load or something)
//
const playEnter = (pathname, node, appears, transition) => {
    let extras = {};
    switch (transition) {
        case 'up':
            extras = {x: 0, y: '100%'};
            break;

        case 'down':
            extras = {x: 0, y: '-100%'};
            break;

        case 'right':
            extras = {x: '100%', y: 0};
            break;

        case 'left':
            extras = {x: '-100%', y: 0};
            break;

        default:
            console.error('Bad transition!');
            break;
    }

    const timeline = gsap.timeline();
    timeline.set(node, {css: {zIndex: 100 /*, overflow: 'hidden'*/}});
    timeline.to(node, {...extras, duration: 0});
    timeline.to(node, {x: 0, y: 0, duration});
    timeline.set(node, {clearProps: 'all'}); //{css: {zIndex: 100, overflow: 'auto'}}); // leave at front and show overflow
    timeline.play();
};

const playExit = (pathname, node, appears, transition) => {
    const movement = '20%';
    let extras = {};
    switch (transition) {
        case 'up':
            extras = {x: 0, y: `-${movement}`};
            break;

        case 'down':
            extras = {x: 0, y: `${movement}`};
            break;

        case 'right':
            extras = {x: `-${movement}`, y: 0};
            break;

        case 'left':
            extras = {x: `${movement}`, y: 0};
            break;

        default:
            console.error('Bad transition!');
            break;
    }

    const timeline = gsap.timeline();
    timeline.set(node, {css: {zIndex: -100}});
    timeline.to(node, {x: 0, y: 0, duration: 0});
    timeline.to(node, {...extras, duration});
    timeline.play();
};

function Route({ currentRoute, pathname }) {
    const pagesStore = usePagesStore()
    console.log('paths store', pagesStore);
    const page = pagesStore.pages?.find((p) => p?.url === pathname)

    // use the currentRoute component OR assemble a page using the page builder OR page not found
    const CurrentRouteComponent = currentRoute?.component || (page && page.page_builder && PageBuilderPage) || PageNotFound;
    const props = currentRoute?.componentProps || {};

    const match = matchPath(pathname, {
        path: currentRoute?.pathname ?? '',
        exact: true,
        strict: false,
    });

    return (
        <CurrentRouteComponent match2={match} page={page} {...props} />
    )
}

export default function Nav() {
    const router = useRouter()
    const pagesStore = usePagesStore()

    const currentPathname = cleanPathname(router.asPath)
    const currentRoute = findRoute(currentPathname)
    const currentPage = pagesStore.pages?.find((p) => p?.url === currentPathname)

    const onExit = (node, appears) => {
        const routerStore = useRouterStore.getState()
        const transition = routerStore.state?.transition || currentPage?.transition || currentRoute?.transition || 'up'; // override transition
        playExit(currentPathname, node, appears, transition);
    }

    const onEnter = (node, appears) => {
        const routerStore = useRouterStore.getState()
        const transition = routerStore.state?.transition || currentPage?.transition || currentRoute?.transition || 'up'; // override transition
        playEnter(currentPathname, node, appears, transition)
    }

    return (
        <TransitionGroup component={null}>
            <Transition key={currentPathname} onEnter={onEnter} onExit={onExit} timeout={duration * 1000} className={`transition ${currentPathname}`}>
                <Route currentRoute={currentRoute} pathname={currentPathname} key={currentPathname}/>
            </Transition>
        </TransitionGroup>
    )
}
