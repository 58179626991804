import React, { useMemo } from 'react';
import {CardGrid, Container} from '../components';
import { useCaseStudiesStore } from '../store/caseStudies';
import {getCaseStudyImages} from '../services/case-study';
import { useRouter } from '../store/router';

export default function CaseStudiesGridModule(props) {
    const router = useRouter()
    const caseStudiesStore = useCaseStudiesStore()

    const onClick = (p) => {
        console.log(`Clicked on `, p);
        router.push(`/case-study${p.pageUrl}`, {prevPath: router.asPath})
    }

    const {name, title} = props;
    const content = useMemo(() => {
        return caseStudiesStore.caseStudies?.map(cs => {
            const newCaseStudy = {...cs};
            const images = getCaseStudyImages(cs);
            newCaseStudy.image = images[0];
            newCaseStudy.underlayImage = images[1];
            return newCaseStudy;
        }) || []
    }, [caseStudiesStore.caseStudies.length])
    
    return (
        <>
            <Container className={`${name} title`}>{title}</Container>
            <CardGrid className={name} content={content} componentProps={{onClick}} />
        </>
    )
}
