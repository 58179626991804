
import PageBuilderPage from '../pages/PageBuilderPage';
import Contact from '../pages/Contact';
import NewsFeed from '../pages/NewsFeed';
import {CaseStudyArticle, NewsArticle} from '../pages/ArticlePage';
import {DevTestPage} from '../pages/DevTestPage'
import {matchPath} from '../utils/path-match';

export var routes = [
    {title: 'Home', pathname: '/', component: PageBuilderPage, transition: 'up'},

    {title: 'About', pathname: '/about', component: PageBuilderPage, transition: 'up'},

    {
        title: 'What',
        pathname: null,
        children: [
            {title: 'Strategy', pathname: '/strategy', component: PageBuilderPage, transition: 'up'},
            {title: 'Digital', pathname: '/digital', component: PageBuilderPage, transition: 'up'},
            {title: 'Creative', pathname: '/creative', component: PageBuilderPage, transition: 'up'},
            {title: 'Communications<br/>and Social Media', pathname: '/social', component: PageBuilderPage, transition: 'up'},
        ],
    },

    {title: 'Work', pathname: '/work', component: PageBuilderPage, transition: 'up'},

    {
        title: 'Team',
        pathname: '/team', 
        component: PageBuilderPage, 
        transition: 'down',
        children: [
            {title: 'Our Team', pathname: '/team', component: PageBuilderPage, transition: 'down'},
            {title: 'Careers', pathname: '/careers', component: PageBuilderPage, transition: 'up'},
        ],
    },

    {title: null, pathname: '/news/:pageUrl', component: NewsArticle, transition: 'up'},
    {title: 'News', pathname: '/news', component: NewsFeed, transition: 'up'},

    {title: 'Contact', pathname: '/contact', component: Contact, transition: 'up'},

    {title: null, pathname: '/case-study/:pageUrl', component: CaseStudyArticle, transition: 'up'},
    {title: null, pathname: '/privacy', component: PageBuilderPage, transition: 'up'},

    {title: null, pathname: '/test', component: PageBuilderPage, transition: 'up'},
    {title: null, pathname: '/devtest', component: DevTestPage, transition: 'up'},
]

export const cleanPathname = (pathname) => {
    if (!pathname) {
        return null
    }
    let cleanPath = pathname.replace('/index.html', '').replace('/index', '')
    if (cleanPath.length > 1 && cleanPath.endsWith('/')) {
        cleanPath = cleanPath.slice(0, -1)
    }
    return cleanPath
}

export const findRoute = (pathname, p_routes = routes) => {
    const cleanPath = cleanPathname(pathname)
    for (const r of p_routes) {
        const match = matchPath(cleanPath, {
            path: r.pathname,
            exact: true,
            strict: false,
        });
        if (match) {
            return r;
        }

        if (r.children) {
            const rc = findRoute(pathname, r.children);
            if (rc) {
                return rc;
            }
        }
    }

    return null;
}
