import React, {Component} from 'react';
import {CSSTransition} from 'react-transition-group';
import Config from '../config';
import assets from '../assets';
import {Image} from '../components/image'
import {useAppStore} from '../store/app'

class LandingComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyPressed: false,
        };
    }

    componentDidMount() {
        if (!Config.DEBUG.SHOW_LANDING) {
            return;
        }

        document.body.style.overflow = 'hidden';
        document.onkeypress = this.onInputEvent;
        document.onmousedown = this.onInputEvent;
    }

    onInputEvent = (e) => {
        if (this.props.appLoading) {
            return;
        }

        this.setState({keyPressed: true});
        document.onkeypress = null;
        document.onmousedown = null;
    }

    showScroll = () => {
        document.body.style.overflow = 'auto';
    }

    render() {
        const {onClick, appLoading} = this.props;
        const {keyPressed} = this.state;

        if (!Config.DEBUG.SHOW_LANDING) {
            return null;
        }

        let showContinuePrompt = !appLoading;
        let visible = appLoading; //!keyPressed;
        return (
            <CSSTransition in={!visible} timeout={300} classNames="fade" onEnter={this.showScroll}>
                <div className="landing-page" onClick={onClick}>
                    <div className="logo">
                        <Image src={assets['landing_logo']}/>
                    </div>

                    {/*<div className="enter-text">{showContinuePrompt && 'Click anywhere to begin'}</div>*/}
                </div>
            </CSSTransition>
        );
    }
}

export default function Landing() {
    const appStore = useAppStore()
    return <LandingComp appLoading={appStore.appLoading}/>
}
