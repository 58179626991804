import React, {Component} from 'react';
import {Container} from '../components';

export default class TwoColumnModule extends Component {
    render() {
        const {column1Content, column2Content, backgroundColour, name, className} = this.props;
        return (
            <Container wrapperClassName={`${name} ${className?.join(' ') ?? ''}`} wrapperStyle={{backgroundColor: backgroundColour}}>
                <div dangerouslySetInnerHTML={{__html: column1Content}} />
                <div dangerouslySetInnerHTML={{__html: column2Content}} />
            </Container>
        );
    }
}
