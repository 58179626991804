import React, {useState, useEffect} from 'react';
import Navigation from './Navigation';
import Menu from './components/Menu';
import Landing from './pages/Landing';
import { useIsMobile } from './utils/use-is-mobile';

export default function App() {
    const isMobile = useIsMobile()
    const [landingVisible, setLandingVisible] = useState(true)

    useEffect(() => {
        document.body.className = isMobile ? 'mobile' : ''
    }, [isMobile])

    const hideLanding = () => {
        setLandingVisible(false)
    }

    return (
        <>
            <Menu />
            <Navigation />
            {/*<Landing onClick={hideLanding} visible={landingVisible} />*/}
        </>
    )
}
