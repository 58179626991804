import React, {Component} from 'react';
import {Container, Image} from '../components';
import Link from 'next/link'

export default class ImageAndContentModule extends Component {
    render() {
        const {columnContent, image, imageAlignment, Video: video, videoAlignment, buttonLabel, buttonLink, name} = this.props;
        if (video) {
            console.log("vid");
        }
        return (
            <Container wrapperClassName={`${name} ${imageAlignment} ${videoAlignment}`}>
                <div className="content-col">
                    <div dangerouslySetInnerHTML={{__html: columnContent}} />

                    {buttonLink && (
                        <Link href={buttonLink} className="btn btn-black">
                            {buttonLabel}
                        </Link>
                    )}
                </div>

                {image &&
                <div className="image-col">
                    <Image src={image?.url} alt="" />
                </div>
                }

                {video &&
                <div className="video-col">
                    <video controls>
                        <source src={video}/>
                    </video>
                </div>
                }
            </Container>
        );
    }
}
