import React from 'react';
import assets from '../assets';
import {Image} from './image'

const Social = (props) => {
    return (
        <div className="social">
            <div className="inner-container">
                <a href="https://www.facebook.com/jpmediaadelaide" target="_blank" className="facebook" rel="noreferrer">
                    <Image src={assets.facebook} alt="" />
                </a>
                <a href="https://www.instagram.com/jpmedia_adelaide/" target="_blank" className="instagram" rel="noreferrer">
                    <Image src={assets.instagram} alt="" />
                </a>
                <a href="https://au.linkedin.com/company/jp-media-australia-" target="_blank" className="linkedin" rel="noreferrer">
                    <Image src={assets.linkedin} alt="" />
                </a>
            </div>
        </div>
    );
};

export default Social;
