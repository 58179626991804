import React, {Component} from 'react';
import {CardGrid, Container} from '../components';

export default class ServiceGridModule extends Component {
    render() {
        const {services, name, title} = this.props;
        return (
            <>
                <Container className={`${name} title`}>{title}</Container>
                <CardGrid title={title} className={name} content={services} componentProps={{clickable: false}} />
            </>
        );
    }
}
