import React, {Component} from 'react';

export default class Card extends Component {
    render() {
        const {title, description} = this.props;

        return (
            <div className="card">
                <div className="title">{title}</div>
                <div className="description">{description}</div>
                <button>More</button>
            </div>
        );
    }
}
