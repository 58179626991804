import React, {Component} from 'react';
import {PageTpl} from '../components';
import PageBuilder from '../page_builder/PageBuilder';

//
// The whole page that is build using a PageBuilder
//
export default class PageBuilderPage extends Component {
    render() {
        const {page} = this.props;
        return (
            <PageTpl>
                <PageBuilder page={page?.pageBuilder} />
            </PageTpl>
        );
    }
}
