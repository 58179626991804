import React, {Component} from 'react';
import {PageTpl, Container, PageHeader} from '../components';
import assets from '../assets';
import { useRouter } from '../store/router';

export default function PageNotFound() {
    const router = useRouter()

    return (
        <PageTpl className="contact-page">
            <PageHeader title="Not Found" subTitle="HTTP 404" />
            <Container className="intro">
                <div>The page you are looking for does not exist.</div>
                <button className="btn-black" onClick={() => router.push('/')} style={{marginTop: '10px'}}>Home</button>
                <div style={{height: '80vh'}}/>
            </Container>
        </PageTpl>
    )
}
