
export const getCaseStudyImages = (caseStudy) => {
    const images = [];
    const pageHeaderModule = caseStudy.pageBuilder.find(m => {
        return m.acfFcLayout === 'page_header_module'
    });
    if (pageHeaderModule) {
        images.push(pageHeaderModule.backgroundImage);
    }

    const imageModule = caseStudy.pageBuilder.find(m => {
        return m.acfFcLayout === 'image_module'
    });
    if (imageModule) {
        images.push(imageModule.image);
    }

    const imageAndContentModule = caseStudy.pageBuilder.find(m => {
        return m.acfFcLayout === 'image_and_content_module'
    });
    if (imageAndContentModule) {
        images.push(imageAndContentModule.image.url);
    }

    return images;
};
