import React, {Component} from 'react';
import {PageTpl, NextStory} from '../components';
import PageNotFound from './PageNotFound';
import {withRouter} from '../store/router';
import PageBuilder from '../page_builder/PageBuilder';
import {getCaseStudyImages} from '../services/case-study';
import { useCaseStudiesStore } from '../store/caseStudies';
import { useNewsStore } from '../store/news'

export class ArticlePage extends Component {
    onClose = () => {
        const { closeUrl, router, routerStore} = this.props;
        const prevPath = routerStore.state?.prevPath ?? closeUrl;
        router.push(prevPath, {transition: 'down'})
    };

    getNextItem = (offset) => {
        const {articles} = this.props;

        let viewingItemIdx = this.getCurrentItemIndex() + offset;
        if (viewingItemIdx < 0) {
            viewingItemIdx = articles.length - 1;
        } else if (viewingItemIdx >= articles.length) {
            viewingItemIdx = 0;
        }
        const nextItem = articles[viewingItemIdx];
        return nextItem;
    };

    changeViewingItem = (offset) => {
        const {router, routerStore, urlPrefix} = this.props;
        const nextItem = this.getNextItem(offset);
        // forward on the prev state so close works after we navigate to different case studies
        router.push(`${urlPrefix}${nextItem.pageUrl}`, {...routerStore.state, transition: offset === -1 ? 'left' : 'right'})
    };

    onLeft = () => {
        this.changeViewingItem(-1);
    };

    onRight = () => {
        this.changeViewingItem(1);
    };

    getCurrentItemIndex = () => {
        const {match2, articles} = this.props;
        const pageUrl = match2[1] // Assuming match2[1] contains the pageUrl
        const caseStudyIndex = articles.findIndex((item) => {
            // Remove the leading '/' from item.pageUrl if it exists
            const sanitizedPageUrl = item.pageUrl.startsWith('/') ? item.pageUrl.slice(1) : item.pageUrl;
            // Compare the sanitized pageUrl with pageUrl
            return sanitizedPageUrl === pageUrl;
        });

        return caseStudyIndex;
    };

    getCurrentItem = () => {
        const idx = this.getCurrentItemIndex();
        if (idx === -1) {
            return null;
        }

        return this.props.articles[idx];
    };

    renderModule = ({definition, pmodule, index}) => {
        const extraProps = [
            {
                name: 'page_header_module',
                onClose: this.onClose,
                onLeft: this.onLeft,
                onRight: this.onRight,
                className: 'with-navigation',
            },
        ];

        const props = extraProps.find((mp) => mp.name === definition.name);
        const Component = definition.component;
        return <Component key={index} {...definition} {...pmodule} {...props} />;
    };

    render() {
        const caseStudy = this.getCurrentItem();
        if (!caseStudy) {
            return <PageNotFound />;
        }

        const rightItem = this.getNextItem(1);
        const images = getCaseStudyImages(rightItem);
        let bgImage = images && images.length ? images[0] : null;

        return (
            <PageTpl className={caseStudy.url}>
                <PageBuilder page={caseStudy?.pageBuilder} renderModule={this.renderModule} />
                <NextStory title={rightItem.title} backgroundImage={bgImage} onClick={this.onRight} />
            </PageTpl>
        );
    }
}

export const ArticlePageWithRouter = withRouter(ArticlePage);

export function CaseStudyArticle(props) {
    const caseStudiesStore = useCaseStudiesStore()
    return <ArticlePageWithRouter {...props} articles={caseStudiesStore.caseStudies} urlPrefix='/case-study/' closeUrl='/'/>
}

export function NewsArticle(props) {
    const newsStore = useNewsStore()
    return <ArticlePageWithRouter {...props} articles={newsStore.news} urlPrefix='/news/' closeUrl='/news'/>
}

