import React, {Component} from 'react';
import {CSSTransition} from 'react-transition-group';
import assets from '../assets';
import { gaEvent } from '../services/analytics';
import { Image, ImageBlurhash } from './image';
import { useIsMobile } from '../utils/use-is-mobile';

export class TeamMemberCard extends Component {
    render() {
        const {className, title, role, quote, bio, phone, email, linkedin, onClick, buttonText} = this.props;

        return (
            <div className={className} onClick={onClick}>
                <div className="title">{title}</div>
                <div className="role">{role}</div>
                <div dangerouslySetInnerHTML={{__html: quote}} className="quote" />
                <div dangerouslySetInnerHTML={{__html: bio}} className="bio" />

                <div className="contact-container">
                    {phone && <a href={`tel:${phone}`} onClick={() => gaEvent({ category: 'contact', action: 'phone_clicked', label: phone })}>{phone}</a>}
                    {email && <a href={`mailto:${email}`} onClick={() => gaEvent({ category: 'contact', action: 'email_clicked', label: email })}>{email}</a>}
                    {linkedin && <a href={linkedin} target='_blank' className='linkedin' rel='noreferrer'><Image src={assets.linkedin} alt='' /></a>}
                </div>

                {onClick && (
                    <button className="button" onClick={onClick}>
                        {buttonText}
                    </button>
                )}
            </div>
        );
    }
}

class TeamMemberHoverCardInner extends Component {
    static defaultProps = {
        buttonText: 'Learn more',
    };

    state = {
        hover: false,
    };

    onClick = () => {
        const {onClick, url, history} = this.props;
        if (onClick) {
            onClick(this.props);
            return;
        }

        if (url) {
            history.push(url);
        }
    };

    render() {
        const {title, image, className, isMobile, ...otherProps} = this.props;
        const {hover} = this.state;
        return (
            <div className={`hover-card ${className ?? ''} ${hover ? 'hover' : ''}`} onMouseEnter={() => this.setState({hover: true})} onMouseLeave={() => this.setState({hover: false})} onClick={this.onClick}>
                <CSSTransition in={hover} timeout={300} classNames="fade">
                    <div className="overlay">
                        <div className="title-img">
                            <ImageBlurhash src={image} alt="" height={isMobile ? 'auto' : null} />
                        </div>
                        <div className="title">{title}</div>
                    </div>
                </CSSTransition>
                <TeamMemberCard title={title} {...otherProps} className="underlay" />
            </div>
        );
    }
}

export function TeamMemberHoverCard(props) {
    const isMobile = useIsMobile()
    return (
        <TeamMemberHoverCardInner isMobile={isMobile} {...props}/>
    )
}