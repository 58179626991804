import React, {Component} from 'react';
import {CSSTransition} from 'react-transition-group';
import {Image, ImageBlurhash} from './image'
import { useIsMobile } from '../utils/use-is-mobile'

class HoverCardComp extends Component {
    static defaultProps = {
        buttonText: 'Learn more',
        clickable: true
    };

    state = {
        hover: false,
    };

    onClick = () => {
        const {onClick, history, location, clickable} = this.props;
        if (!clickable) {
            return;
        }

        const url = this.props.url || this.props.pageUrl;
        if (onClick) {
            onClick(this.props);
            return;
        }

        if (url) {
            history.push({pathname: url, state: {prevPath: location.pathname}});
        }
    };
    render() {
        const {title, description, className, buttonText, clickable, image, underlayImage, isMobile} = this.props;
        const {hover} = this.state;

        return (
            <div
                className={`hover-card ${className ?? ''} ${hover ? 'hover' : ''} ${clickable ? 'clickable' : 'not-clickable'}`}
                onMouseEnter={() => this.setState({hover: true})}
                onMouseLeave={() => this.setState({hover: false})}
                onClick={this.onClick}
            >
                <CSSTransition in={hover} timeout={300} classNames="fade">
                    <div className="overlay">
                        <div className="title-img">
                            <ImageBlurhash src={image} alt="" height={isMobile ? 'auto' : null}/>
                        </div>

                        <div className="title">{title}</div>
                    </div>
                </CSSTransition>

                <div className={`underlay ${underlayImage ? 'with-underlay-img' : ''}`}>
                    {underlayImage && 
                        <div className="underlay-img">
                            <ImageBlurhash src={underlayImage} alt="" />
                        </div>
                    }

                    <div className="title">{title}</div>
                    <div className="description" dangerouslySetInnerHTML={{__html: description}} />
                    <button className="button">{buttonText}</button>
                </div>
            </div>
        );
    }
}

export default function HoverCard(props) {
    const isMobile = useIsMobile()
    return (
        <HoverCardComp isMobile={isMobile} {...props}/>
    )
}
