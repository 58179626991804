import React, {Component} from 'react';
import {TeamMemberCard, TeamMemberHoverCard} from '../components/TeamMemberCard';
import Modal from '../components/Modal';
import {CardGrid} from '../components';
import assets from '../assets';
import {Image, ImageBlurhash} from '../components/image'

export default class TeamMembersGridModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTeamMember: null,
        };
    }

    onClick = (props) => {
        this.setState({currentTeamMember: props});
    };

    onLeft = () => {
        const {currentTeamMember} = this.state;
        const {members} = this.props;
        const idx = members.findIndex((t) => t.id === currentTeamMember.id);
        const nextTeamMember = members[idx === 0 ? members.length - 1 : idx - 1];
        this.setState({currentTeamMember: nextTeamMember});
    };

    onRight = () => {
        const {currentTeamMember} = this.state;
        const {members} = this.props;
        const idx = members.findIndex((t) => t.id === currentTeamMember.id);
        const nextTeamMember = members[idx === members.length - 1 ? 0 : idx + 1];
        this.setState({currentTeamMember: nextTeamMember});
    };

    renderModal = () => {
        const {currentTeamMember} = this.state;
        const {image} = currentTeamMember ?? {};

        return (
            <Modal visible={currentTeamMember} closemodal={() => this.setState({currentTeamMember: null})} type="fadeInUp" className="team-page">
                <div className="btn-left-container">
                    <button className="btn btn-left" onClick={this.onLeft}>
                        <Image src={assets['arrow_down']} />
                    </button>
                </div>

                <div className="modal-body-container">
                    <div className="btn-x-container">
                        <button className="btn-x" onClick={() => this.setState({currentTeamMember: null})}>
                            <Image src={assets['x']}/>
                        </button>
                    </div>

                    <div className="title-img">
                        <ImageBlurhash src={image} key={image}/>
                    </div>
                    <TeamMemberCard {...currentTeamMember} onClick={null} className="container" />
                </div>

                <div className="btn-right-container">
                    <button className="btn btn-right" onClick={this.onRight}>
                        <Image src={assets['arrow_down']}/>
                    </button>
                </div>
            </Modal>
        );
    };

    render() {
        const {members, name} = this.props;

        return (
            <div className={name}>
                <CardGrid content={members} componentProps={{className: 'team-hover-card', buttonText: 'Read More', onClick: this.onClick}} component={TeamMemberHoverCard} />
                {this.renderModal()}
            </div>
        );
    }
}
