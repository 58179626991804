import React, {Component} from 'react';
import {Container, Image, ImageBlurhash} from '../components';

export default class ImageModule extends Component {
    render() {
        const {image, name} = this.props;
        return (
            <Container wrapperClassName={name}>
                <ImageBlurhash src={image} alt="" height="auto"/>
            </Container>
        );
    }
}
