import { useImagesStore } from '../store/images'
import { Blurhash } from 'react-blurhash'
import { useElementSize } from 'usehooks-ts'
import { useImageOnLoad } from 'usehooks-ts'
import { useElementFit, ScaleMode } from 'use-element-fit'

// Hook to help us combine img, blurhash and our imageStore
function useImg({ style, ...other }) {
    const { height: heightProp } = other
    const imageStore = useImagesStore()
    const [setSizeElementRef, { width, height }] = useElementSize()
    const { handleImageOnLoad, css } = useImageOnLoad()
    const imageData = imageStore?.images?.[other.src]
   
    // if height == 'auto' lets auto compute the height based on the image aspect ratio
    let autoHeight = null
    if (imageData) {
        const aspectRatio = imageData.width / imageData.height
        if (heightProp == 'auto') {
            autoHeight = width / aspectRatio
        }
    }

    const elFit = useElementFit(imageData?.width || 1, imageData?.height || 1, ScaleMode.COVER); // half the width and the full height of parent element

    const setSizeRef = (ref) => {
        elFit.ref.current = ref
        setSizeElementRef(ref)
    }

    const setImageRef = (ref) => {
        if (ref?.complete) {
            handleImageOnLoad()
        }
    }

    const blurhashVisible = width > 0 && height > 0 && imageData?.blurhash?.hash

    return {
        //width,
        //height,
        //css,
        containerProps: {
            ref: setSizeRef,
            style: {
                display: 'inline-block',
                width: '100%',
                height: autoHeight || '100%',
                //background: 'yellow',
                position: 'relative',
                overflow: 'hidden'
            }
        },
        imgProps: {
            ref: setImageRef,
            onLoad: handleImageOnLoad,
            src: other.src,
            style: {
                //objectFit: 'cover',
                position: 'absolute',
                width: elFit.width,
                height: elFit.height,
                top: elFit.y,
                left: elFit.x,
                ...css.fullSize,
                //opacity: 0.02,
            }
        },

        blurhashVisible,
        blurhashProps: {
            width,
            height,
            hash: imageData?.blurhash?.hash,
            style: {
                width: elFit.width,
                height: elFit.height,
            }
        },
        blurhashContainerProps: {
            style: {
                width: elFit.width,
                height: elFit.height,
                top: elFit.y,
                left: elFit.x,
                position: 'absolute',
                ...css.thumbnail
            }
        }
    }
}

const BlurhashContainer = ({ blurhashVisible, blurhashContainerProps, blurhashProps }) => {
    return (
        <div className="_blurhash-container" {...blurhashContainerProps}>
            {blurhashVisible && <Blurhash {...blurhashProps}/>}
        </div>
    )
}

export function ImageBlurhash(props) {
    const {style, ...other} = props
    const im = useImg(props)

    return (
        <div {...im.containerProps}>
            <BlurhashContainer {...im}/>
            <img {...im.imgProps}/>
        </div>
    )
}

export function Image(props) {
    return (
        <img {...props}/>
    )
}

