import React, {Component} from 'react';
import Container from './Container';
import {Background} from './Background';
import assets from '../assets';
import {withRouter} from '../store/router';
import {Image, ImageBlurhash} from './image';
import {Helmet} from 'react-helmet';

class PageHeader extends Component {
    constructor(props) {
        super(props);

        if (this.usesVideo()) {
            console.log('Video loading....');
            //props.setVideoLoading(true);
        }
    }

    componentDidMount() {
        // play all videos at half speed!
        /*const videoElement = document.getElementById('header-video');
        if (videoElement) {
            videoElement.playbackRate = 0.5;
            videoElement.addEventListener("canplaythrough", () => {
                console.log("Video loaded");
                //this.props.setVideoLoading(false);
            }, false);
        }*/
    }

    onLeft = () => {
        const {onLeft, router, leftUrl} = this.props;
        if (onLeft) {
            onLeft();
        } else {
            router.push(leftUrl, {transition: 'left'});
        }
    };

    onRight = () => {
        const {onRight, router, rightUrl} = this.props;
        if (onRight) {
            onRight();
        } else {
            router.push(rightUrl, {transition: 'right'});
        }
    };

    usesVideo = () => {
        const {backgroundVideo} = this.props;
        if (!backgroundVideo) {
            return false;
        }

        return true;
    };

    onDown = () => {
        const page = document.getElementsByClassName('page')[0];
        const e = document.getElementsByClassName('page-header')[0];
        page.scroll({left: 0, top: e.scrollHeight, behavior: 'smooth'});
    };

    renderBefore = () => {
        const {renderBefore, background, backgroundImage, backgroundProperties, backgroundVideo} = this.props;
        if (renderBefore) {
            return renderBefore(this.props);
        }

        if (backgroundImage || this.usesVideo()) {
            return (
                <div className="background">
                    <ImageBlurhash src={backgroundImage} />
                    {this.usesVideo() && (
                        <video autoPlay loop muted id="header-video">
                            <source src={backgroundVideo} type="video/mp4" />
                        </video>
                    )}
                </div>
            );
        }

        return <Background type={background} properties={backgroundProperties} />;
    };

    render() {
        const {title, subTitle, titleComponent, tags, backgroundImage, onLeft, onRight, leftUrl, rightUrl, onClose, className, router} = this.props;
        const showLeftArrow = onLeft || (leftUrl && leftUrl.length > 0);
        const showRightArrow = onRight || (rightUrl && rightUrl.length > 0);

        if (titleComponent) {
            return titleComponent(this.props);
        }

        const cleanTitle = title?.replace('<br/>', ' ').trim();
        const fullTitle = !!cleanTitle ? `JP Media - ${cleanTitle}` : 'JP Media';

        return (
            <Container wrapperClassName={`page-header page_header_module ${className ?? ''}`} /*wrapperStyle={{backgroundImage: `url(${backgroundImage})`}}*/ before={this.renderBefore}>
                <Helmet>
                    <title>{fullTitle}</title>
                    <meta name="title" property="og:title" content={fullTitle} />
                    {/* <meta name="image" property="og:image" content={backgroundImage} /> */}
                </Helmet>

                {onClose && (
                    <button className="btn-x" onClick={onClose}>
                        <Image src={assets['x']} />
                    </button>
                )}

                <div className={['title-arrows-container', router.asPath == '/' ? 'home' : ''].join(' ')}>
                    {(showLeftArrow || showRightArrow) && (
                        <div className="arrows">
                            {showLeftArrow && (
                                <button className="btn btn-left" onClick={this.onLeft}>
                                    <Image src={assets['arrow_down']} />
                                </button>
                            )}
                            {showRightArrow && (
                                <button className="btn btn-right" onClick={this.onRight}>
                                    <Image src={assets['arrow_down']} />
                                </button>
                            )}
                        </div>
                    )}

                    <div className="title" dangerouslySetInnerHTML={{__html: title}} />
                    <div className="subtitle" dangerouslySetInnerHTML={{__html: subTitle}} />
                    <div className="tags" dangerouslySetInnerHTML={{__html: tags}} />
                    <button className="btn btn-down arrow-down" onClick={this.onDown}>
                        <Image src={assets['arrow_down']} />
                    </button>
                </div>
            </Container>
        );
    }
}

export default withRouter(PageHeader);
