//import React, {Component} from 'react';

import dynamic from 'next/dynamic'

const BackgroundComp = dynamic(() => import(/* webpackChunkName: "background-bundle" */'./background-bundle'), { ssr: false })

export function Background(props) {
    if (!props.type) {
        return null
    }
    return (
        <div className="background">
            <BackgroundComp {...props}/>
        </div>
    )
}
