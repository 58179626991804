import React, {Component} from 'react';
import assets from '../assets';
import Link from 'next/link'
import Container from './Container';
import { Image } from './image';

export default class WhatWeDo extends Component {
    renderItem = (props, index) => {
        const {className} = this.props;
        const {title, shortExcerpt, excerpt, buttonLabel, url, id} = props;
        const full = className && className.includes('full');

        return (
            <Link href={url} className="card" key={index} id={id}>
                <div className="header">
                    <div className="title">{title}</div>
                    <div className="btn-right">
                        <Image src={assets['arrow_down']} alt="" />
                    </div>
                </div>
                <div className="description">{full ? excerpt : shortExcerpt}</div>
                <div className="btn btn-opacity">{buttonLabel}</div>
            </Link>
        );
    };

    render() {
        const {title, className, pages} = this.props;
        return (
            <Container wrapperClassName={`what-we-do ${className}`}>
                {title && <div className="title">{title}</div>}
                <div className="card-grid">
                    {pages?.map((item, index) => {
                        return this.renderItem(item, index);
                    })}
                </div>
            </Container>
        );
    }
}
