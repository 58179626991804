import React, {Component} from 'react';
import {Container} from '../components';
import Link from 'next/link'

export default class OneColumnModule extends Component {
    render() {
        const {column1Content, buttonLabel, buttonLink, backgroundColour, name, className} = this.props;
        return (
            <Container wrapperClassName={`${name} ${className?.join(' ') ?? ''}`} wrapperStyle={{backgroundColor: backgroundColour}}>
                <div dangerouslySetInnerHTML={{__html: column1Content}} />

                {buttonLink && (
                    <Link href={buttonLink} className="btn btn-black">
                        {buttonLabel}
                    </Link>
                )}
            </Container>
        );
    }
}
