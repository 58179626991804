import React, {Component} from 'react';
import Container from './Container';
import assets from '../assets';
import { Image, ImageBlurhash } from './image';

export default class NextStory extends Component {
    render() {
        const {title, backgroundImage, onClick} = this.props;
        return (
            <Container 
                wrapperClassName="next-story" 
                //wrapperStyle={{backgroundImage: `url(${backgroundImage})`}} 
                wrapperOnClick={onClick}
                before={() => {
                    return (
                        <div /*style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}*/ className="before">
                            <ImageBlurhash src={backgroundImage} /*style={{width: '100%', height: '100%', objectFit: 'cover'}}*/ />
                            <div className="before-image-overlay" />
                        </div>
                    )
                }}
            >
                <div className="title">Next Story</div>
                <div className="subtitle">
                    <span>{title}</span>
                    <Image src={assets['arrow_down']} />
                </div>
                <button>Read now</button>
            </Container>
        );
    }
}
