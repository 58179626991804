import React, {Component} from 'react';
import { Container } from '../components';
import { postCareerContact } from '../services/contact'

const fields = ['name', 'phone_number', 'email', 'message'];

class CareerContactFormModuleComp extends Component {
    state = {
        busy: false,
        submittedOk: false
    };

    onSubmit = (e) => {
        const obj = {};
        let isValid = true;
        for (const f of fields) {
            const domInput = document.getElementById(f);
            const valid = domInput.checkValidity();
            domInput.classList.remove('invalid');
            const value = domInput.value;
            if (!valid) {
                isValid = false;
                domInput.classList.add('invalid');
            }
            obj[f] = value;
        }

        if (!isValid) {
            return;
        }

        this.setState({busy: true});
        postCareerContact(obj)
            .then((response) => {
                if (response.status == 200) {
                    this.setState({busy: false, submittedOk: true});
                } else {
                    alert('An error occured submitting your enquiry');
                    this.setState({busy: false});
                }
            })
            .catch((error) => {
                alert('An error occured submitting your enquiry');
                this.setState({busy: false});
            });
    }

    render() {
        const {show} = this.props;
        const {busy, submittedOk} = this.state;
        if (!show) {
            return null;
        }

        return (
            <Container className="form">
                {!submittedOk &&
                <>
                    <div className="row-1" style={{minWidth: '50vw'}}>
                        <div className="col-2">
                            <input placeholder="Your Name" id="name" required />
                            <input placeholder="Phone" id="phone_number" pattern="[0-9]{8,12}" type="tel" required />
                            <input placeholder="Email" id="email" type="email" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" required />
                            <textarea placeholder="Your message..." id="message" required />
                            <button className="btn-black submit" onClick={this.onSubmit} disabled={busy}>
                                Send
                            </button>

                        </div>
                    </div>
                </>
                }
                {submittedOk && <div className="message">Your enquiry has been submitted</div>}
            </Container>
        );
    }
}

export default CareerContactFormModuleComp;
