import React, {Component} from 'react';
import Link from 'next/link'
import Social from './Social';

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div className="copyright">All content Copyright &#169; JP Media 2021</div>
                <Social />
                <div className="privacy">
                    <Link href="privacy">
                        Privacy
                    </Link>
                </div>
            </div>
        );
    }
}

export default Footer;
