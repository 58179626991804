//
// Page Template
//
// Provides a base template for rendering all the pages
//
import React, {Component} from 'react';
import Footer from './Footer';

class PageTpl extends Component {
    render() {
        const {className, children} = this.props;

        return (
            <div className={`page ${className}`}>
                {children}
                <Footer />
            </div>
        );
    }
}

export default PageTpl;
